import React from "react";

const NoActivityFound = () => {
  return (
    <div className="mt-5 text-center">
      <h3 className="no-activity-tile">You don't have any Activity</h3>
    </div>
  );
};

export default NoActivityFound;
