import { useState } from "react";
import { currencyApi } from "../config/axiosUtils";

const useExchangeRate = () => {
  const [exchangeRates, setExchangeRates] = useState(null);
   const [exchangeLoading, setExchangeRating] = useState(false);

  const getExchangeRate = async (base, symbols) => {
    setExchangeRating(true)
    try {
      const response = await currencyApi.GetExchangeRate(base, symbols);

      setExchangeRates(response?.data?.rates);
    } catch (error) {
      console.log(error);
    }finally{
      setExchangeRating(false)
    }
  };

  return [getExchangeRate, exchangeRates ,exchangeLoading];
};

export default useExchangeRate;
