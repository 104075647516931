import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
const FooterData = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_LOCAL_API_URL
          }/admin/cms-page/${searchParams.get("id")}`
        );
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);
  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }
  if (error)
    return <div>Error: {error.response?.data?.message || error.message}</div>;
  return (
    <main className="main-content">
      {data ? (
        <div dangerouslySetInnerHTML={{ __html: data.page.content }} />
      ) : (
        <div>No content available.</div>
      )}
    </main>
  );
};
export default FooterData;