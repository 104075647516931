import { useEffect, useState } from "react";
import { AdminMetaData } from "../config/axiosUtils";

const useCurrencyListOption = () => {
  const [currecyList, setcurrecyList] = useState([
    { _id: 1, name: "EUR" },
    { _id: 2, name: "BMD" },
    { _id: 3, name: "AMD" },
    { _id: 4, name: "USD" },
  ]);

  useEffect(() => {
    async function fetchCategroyList() {
      try {
        const response = await AdminMetaData.currencyList();
        // console.log(response);
        setcurrecyList(response.data?.currencyList);
      } catch (error) {
        // console.error(error);
      }
    }

    fetchCategroyList();
  }, []);

  return currecyList;
};

export default useCurrencyListOption;
