import { useEffect, useState } from "react";
import { orderApi } from "../config/axiosUtils";
import useAuth from "./useAuth";
import { toast } from "react-toastify";

const useCompanionActivity = () => {
  const [loading, setLoading] = useState(false);
  const [Contribution, setContributionTrackers] = useState(null);
  const token = JSON.parse(useAuth()?.token);
  const getGoalActivity = async () => {
    try {
      setLoading(true);
      const res = await orderApi.fetchCompanionActivity(token);
      setContributionTrackers({
        data: res?.data?.data,
        status: "success",
        message: "successfully Fetched data",
      });
    } catch (e) {
      setContributionTrackers({
        data: [],
        status: "success",
        message: e?.response?.data?.message || e?.response?.data?.error,
      });
      toast.error(e?.response?.data?.message || e?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGoalActivity();
  }, []);

  return [Contribution, loading, getGoalActivity];
};

export default useCompanionActivity;
