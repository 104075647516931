import React, { useState } from "react";
import BrandImg from "../img/NewLogo.png";
import { Link } from "react-router-dom";
import LoginFooterElement from "../elements/LoginFooterElement";
import { BagIcon, RightBtn, UserIcon } from "../elements/SvgElements";
import ProgressBar from "react-bootstrap/ProgressBar";

import Left from "../svgfile/Left.svg";
const LandingLog = () => {
  const [showComment, setShowComment] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const handleSignUpClick = () => {
    setShowComment(false);
    setShowSignUp(true);
  };

  return (
    <>
      <section className="user-welcome">
        <div className="d-flex h87">
          <div className="image-container"></div>
          <div className="form-container cstpx-10">
            {showComment && (
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12 mpx-1">
                  <form className="user-enter-form">
                    <div className="form-start">
                      <div className="form-brand ">
                        <img
                          className="l-form-brand w-124 w-94"
                          src={BrandImg}
                          alt="logo"
                        />
                        <div className="landing-head mt-5">
                          <h1>Fight The Good Fight</h1>
                          <p className="para">
                            {" "}
                            Embrace the Future of Fanhood! <br /> With Fight
                            Companion, supporting your beloved fighters has
                            never been simpler.{" "}
                          </p>
                        </div>
                        <div className="tab-btn d-flex text-center mt-5">
                          <button
                            type="button"
                            className="tab-btn-signin"
                            onClick={handleSignUpClick}
                          >
                            {" "}
                            Sign up{" "}
                          </button>
                          <Link
                            className="text-white text-decoration-none"
                            to={`/signin`}
                          >
                            <button type="button" className="tab-btn-signup">
                              Sign in
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {showSignUp && (
              <>
                <form className="user-signup-form">
                  <div className="form-start-signup">
                    <div className="form-brand text-start">
                      <span className="back-i-btn">
                        <button
                          className="backbtn"
                          type="button"
                          onClick={() => {
                            setShowComment(true);
                            setShowSignUp(false);
                          }}
                        >
                          <img src={Left} alt="back" />
                        </button>
                      </span>
                      <div className="m-auto w-60">
                        <ProgressBar now={30} variant="black" />
                      </div>
                      <div className="signup-view mt-4 text-left">
                        <h4 className="mb-2">
                          What kind of account do you want to open?
                        </h4>
                      </div>
                      {/* ... Sign-up content ... */}
                      <section className="main-container">
                        <Link
                          className="main-container text-decoration-none"
                          state={{ role: "fighter" }}
                          to="/signup/companion"
                        >
                          <div className="image-wrapper">
                            <div>{UserIcon}</div>
                          </div>
                          <div className="description-container">
                            <h2 className="title-log-card">
                              Companion Account
                            </h2>
                            <p className="description-log-card">
                              Support your favorite fighters around the world.
                            </p>
                          </div>
                          {RightBtn}
                        </Link>
                      </section>
                      <section className="main-container">
                        <Link
                          className="main-container text-decoration-none"
                          state={{ role: "fighter" }}
                          to="/signup/fighter"
                        >
                          <div className="image-wrapper">
                            <div>{BagIcon}</div>
                          </div>
                          <div className="description-container">
                            <div>
                              <h2 className="title-log-card">
                                Fighter Account
                              </h2>
                              <p className="description-log-card">
                                Fulfill your dreams with support from your
                                biggest fans.
                              </p>
                            </div>
                          </div>
                          <div>{RightBtn}</div>
                        </Link>
                      </section>
                    </div>
                  </div>
                </form>{" "}
              </>
            )}
          </div>
        </div>
      <LoginFooterElement />
      </section>
    </>
  );
};

export default LandingLog;
