// src/Tooltip.js
import React, { useState } from "react";

const Tooltip = ({ children, content }) => {
  const [visible, setVisible] = useState(false);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div className="tooltip-container">
      <div
        className="tooltip-trigger d-flex"
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
      {visible && <div className="tooltip-content">{content}</div>}
    </div>
  );
};

export default Tooltip;
