import  { useEffect, useState } from 'react'
import { currencyApi } from '../config/axiosUtils';


const useGetDollarExchange = (base,symbols) => {
    const [dollarExchange, setdollarExchange] = useState({
        rates:null,
        loading: false,
    });

    const getExchangeRate = async () => {
      try {
        setdollarExchange((prev)=>{
            return {
                ...prev,
                loading: true
            }
        })
        const response = await currencyApi.GetExchangeRate(base, symbols);
        setdollarExchange((prev)=>{
            return {
                ...prev,
                rates: response?.data?.rates
            }
        })
      } catch (error) {
        console.log(error);
      }finally{
        setdollarExchange((prev)=>{
            return {
                ...prev,
                loading: false,
            }
        })
       
      }
    };
    useEffect(()=>{
        getExchangeRate()
    },[base,symbols])

    return dollarExchange
}

export default useGetDollarExchange
