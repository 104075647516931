import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currency: [],
  exchangeRate: [],
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      state.currency = action.payload.currency;
    },
    // notused it in the code yet
    setExchangeRate: (state, action) => {
      state.exchangeRate = action.payload;
    },
  },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;
