import { useState } from "react";
import { stripeApi } from "../config/axiosUtils";
import useAuth from "./useAuth";
import { toast } from "react-toastify";

const useFetchStripeBalance = () => {
  const token = JSON.parse(useAuth()?.token);
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchBalance = async () => {
    try {
      setLoading(true);
      const res = await stripeApi.getAccountBlance(token);
      if (res.data.balance?.livemode === false) {
        setBalance(res?.data?.balance?.pending[0]);
      }
      if (res.data.balance?.livemode === true) {
        setBalance(res?.data?.balance?.instant_available[0]);
      }
    } catch (error) {
      toast.error("Failed to fetch balance");
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  return [balance, loading, error, fetchBalance];
};

export default useFetchStripeBalance;
