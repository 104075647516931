import { toast } from "react-toastify";

const copyToCLipboard = (text) => {
    navigator.clipboard.writeText(
      `https://www.fightcompanion.io/preview/${text}`
    );
    toast.info("Copied to clipboard");
  }


export default copyToCLipboard;