import React, { useState } from "react";

const GoalType = ({ register, watch, errors }) => {
  const [goalTypeClicked, setGoalTypeClicked] = useState(false);

  const selectedGoalType = watch("goalType");
  return (
    <div className="form-group text-left mb-2">
      <label
        className={`form-head mb-2 goaldropdownBtn form-control position-relative ${
          errors?.goalType ? "error-border-profile" : ""
        }`}
        type="button"
        onClick={() => setGoalTypeClicked(!goalTypeClicked)}
      >
        Goal Type
        <svg
          className="drop-down-arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 26 26"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.8597 10.118C19.2755 9.70221 19.9496 9.70221 20.3654 10.118C20.7812 10.5338 20.7812 11.2079 20.3654 11.6237L13.9774 18.0117C13.5616 18.4275 12.8875 18.4275 12.4717 18.0117L6.08365 11.6237C5.66787 11.2079 5.66787 10.5338 6.08365 10.118C6.49943 9.70221 7.17354 9.70221 7.58933 10.118L13.2245 15.7532L18.8597 10.118Z"
            fill="#616161"
          />
        </svg>
      </label>

      {goalTypeClicked && (
        <div className="goal-container">
          {/* single goal container */}
          <div className="single-goal-cont">
            <div className="goal-select-grp">
              <input
                type="radio"
                name="goalType"
                id="singleGoal"
                value="single"
                {...register("goalType", {
                  required: {
                    value: true,
                    message: "Please select a goal type",
                  },
                })}
              />
              <label htmlFor="singleGoal" className="goal-price-typo">
                Single Goal
              </label>
            </div>
            {selectedGoalType === "single" && (
              <div className="single-goal-content">
                <input
                  type="checkbox"
                  id="repeatPurchase"
                  value="multiple"
                  defaultChecked
                  {...register("goalPurchaseType", {
                    defaultValue: "multiple",
                  })}
                />
                <label htmlFor="repeatPurchase">
                  <span className="typo-grey-14">Allow Repeat Purchases</span>
                  <span className="typo-grey-12">
                    Check if you want the item to be bought again. Without
                    checking, it's gone after the first purchase
                  </span>
                </label>
              </div>
            )}
          </div>

          {/* subscription-goal-container */}
          <div className="sub-goal-cont">
            <div className="goal-select-grp">
              <input
                type="radio"
                name="goalType"
                id="subscriptionGoal"
                value="subscription"
                {...register("goalType", {
                  required: {
                    value: true,
                    message: "Please select a goal type",
                  },
                })}
              />
              <label htmlFor="subscriptionGoal">Subscription</label>
            </div>
            {selectedGoalType === "subscription" && (
              <div className="sub-goal-content row">
                <div className="sub-left col-5">
                  <input
                    type="checkbox"
                    name="goalPurchaseType"
                    id="oneTime"
                    value="multiple"
                    defaultChecked
                    {...register("goalPurchaseType")}
                  />
                  <label htmlFor="oneTime" className="typo-grey-14">
                    Allow One time Purchase
                  </label>
                </div>
                <div className="sub-right col-6">
                  <div className="sub-right-inputpgrp">
                    <input
                      type="checkbox"
                      name="subscriptionType"
                      value="Daily"
                      id="Daily"
                      {...register("subscriptionType", {
                        required:
                          "Please select at least one subscription type",
                      })}
                    />
                    <label htmlFor="Daily" className="typo-grey-14">
                      Daily
                    </label>
                  </div>
                  <div className="sub-right-inputpgrp">
                    <input
                      type="checkbox"
                      name="subscriptionType"
                      value="Weekly"
                      id="Weekly"
                      {...register("subscriptionType", {
                        required:
                          "Please select at least one subscription type",
                      })}
                    />
                    <label htmlFor="Weekly" className="typo-grey-14">
                      Weekly
                    </label>
                  </div>
                  <div className="sub-right-inputpgrp">
                    <input
                      type="checkbox"
                      name="subscriptionType"
                      value="Bi Weekly"
                      id="BiWeekly"
                      {...register("subscriptionType", {
                        required:
                          "Please select at least one subscription type",
                      })}
                    />
                    <label htmlFor="BiWeekly" className="typo-grey-14">
                      Bi-Weekly
                    </label>
                  </div>
                  <div className="sub-right-inputpgrp">
                    <input
                      type="checkbox"
                      name="subscriptionType"
                      value="Monthly"
                      id="Monthly"
                      {...register("subscriptionType", {
                        required:
                          "Please select at least one subscription type",
                      })}
                    />
                    <label htmlFor="Monthly" className="typo-grey-14">
                      Monthly
                    </label>
                  </div>
                </div>

                <p className="profile-error-message">
                  {errors?.subscriptionType?.message}
                </p>
              </div>
            )}
          </div>

          {/* crowd-goal */}
          <div className="crowd-fund">
            <div className="goal-select-grp">
              <input
                type="radio"
                name="goalType"
                id="crowdFundGoal"
                value="crowd"
                {...register("goalType", {
                  required: {
                    value: true,
                    message: "Please select a goal type",
                  },
                })}
              />
              <label htmlFor="crowdFundGoal">Crowd Fund</label>
            </div>
            {selectedGoalType === "crowd" && (
              <label htmlFor="crowdFundGoal" className="typo-grey-14">
                Allows multiple fans to contribute to your goal.
              </label>
            )}
          </div>
        </div>
      )}

      <p className="profile-error-message">{errors?.goalType?.message}</p>
    </div>
  );
};

export const EditGoalType = ({ register, watch, errors }) => {
  const [goalTypeClicked, setGoalTypeClicked] = useState(false);

  const selectedGoalType = watch("goalType");
  return (
    <div className="form-group text-left mb-2">
      <label
        className={`form-head mb-2 goaldropdownBtn form-control position-relative ${
          errors?.goalType ? "error-border-profile" : ""
        }`}
        type="button"
        onClick={() => setGoalTypeClicked(!goalTypeClicked)}
      >
        Goal Type
        <svg
          className="drop-down-arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 26 26"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.8597 10.118C19.2755 9.70221 19.9496 9.70221 20.3654 10.118C20.7812 10.5338 20.7812 11.2079 20.3654 11.6237L13.9774 18.0117C13.5616 18.4275 12.8875 18.4275 12.4717 18.0117L6.08365 11.6237C5.66787 11.2079 5.66787 10.5338 6.08365 10.118C6.49943 9.70221 7.17354 9.70221 7.58933 10.118L13.2245 15.7532L18.8597 10.118Z"
            fill="#616161"
          />
        </svg>
      </label>

      {goalTypeClicked && (
        <div className="goal-container">
          {/* single goal container */}
          <div className="single-goal-cont">
            <div className="goal-select-grp">
              <input
                type="radio"
                name="goalType"
                id="singleGoal"
                value="single"
                {...register("goalType", {
                  required: {
                    value: true,
                    message: "Please select a goal type",
                  },
                })}
              />
              <label htmlFor="singleGoal" className="goal-price-typo">
                Single Goal
              </label>
            </div>
            {selectedGoalType === "single" && (
              <div className="single-goal-content">
                <input
                  type="checkbox"
                  id="repeatPurchase"
                  value="multiple"
                  defaultChecked={
                    watch("goalPurchaseType") === "multiple" ? true : false
                  }
                  {...register("goalPurchaseTypeEdit")}
                />
                <label htmlFor="repeatPurchase">
                  <span className="typo-grey-14">Allow Repeat Purchases</span>
                  <span className="typo-grey-12">
                    Check if you want the item to be bought again. Without
                    checking, it's gone after the first purchase
                  </span>
                </label>
              </div>
            )}
          </div>

          {/* subscription-goal-container */}
          <div className="sub-goal-cont">
            <div className="goal-select-grp">
              <input
                type="radio"
                name="goalType"
                id="subscriptionGoal"
                value="subscription"
                {...register("goalType", {
                  required: {
                    value: true,
                    message: "Please select a goal type",
                  },
                })}
              />
              <label htmlFor="subscriptionGoal">Subscription</label>
            </div>
            {selectedGoalType === "subscription" && (
              <div className="sub-goal-content row">
                <div className="sub-left col-5">
                  <input
                    type="checkbox"
                    name="goalPurchaseType"
                    id="oneTime"
                    value="multiple"
                    defaultChecked={
                      watch("goalPurchaseType") === "multiple" ? true : false
                    }
                    {...register("goalPurchaseTypeEdit")}
                  />
                  <label htmlFor="oneTime" className="typo-grey-14">
                    Allow One time Purchase
                  </label>
                </div>
                <div className="sub-right col-6">
                  <div className="sub-right-inputpgrp">
                    <input
                      type="checkbox"
                      name="subscriptionType"
                      value="Daily"
                      id="Daily"
                      {...register("subscriptionType", {
                        required:
                          "Please select at least one subscription type",
                      })}
                    />
                    <label htmlFor="Daily" className="typo-grey-14">
                      Daily
                    </label>
                  </div>
                  <div className="sub-right-inputpgrp">
                    <input
                      type="checkbox"
                      name="subscriptionType"
                      value="Weekly"
                      id="Weekly"
                      {...register("subscriptionType", {
                        required:
                          "Please select at least one subscription type",
                      })}
                    />
                    <label htmlFor="Weekly" className="typo-grey-14">
                      Weekly
                    </label>
                  </div>
                  <div className="sub-right-inputpgrp">
                    <input
                      type="checkbox"
                      name="subscriptionType"
                      value="Bi Weekly"
                      id="BiWeekly"
                      {...register("subscriptionType", {
                        required:
                          "Please select at least one subscription type",
                      })}
                    />
                    <label htmlFor="BiWeekly" className="typo-grey-14">
                      Bi-Weekly
                    </label>
                  </div>
                  <div className="sub-right-inputpgrp">
                    <input
                      type="checkbox"
                      name="subscriptionType"
                      value="Monthly"
                      id="Monthly"
                      {...register("subscriptionType", {
                        required:
                          "Please select at least one subscription type",
                      })}
                    />
                    <label htmlFor="Monthly" className="typo-grey-14">
                      Monthly
                    </label>
                  </div>
                </div>

                <p className="profile-error-message">
                  {errors?.subscriptionType?.message}
                </p>
              </div>
            )}
          </div>

          {/* crowd-goal */}
          <div className="crowd-fund">
            <div className="goal-select-grp">
              <input
                type="radio"
                name="goalType"
                id="crowdFundGoal"
                value="crowd"
                {...register("goalType", {
                  required: {
                    value: true,
                    message: "Please select a goal type",
                  },
                })}
              />
              <label htmlFor="crowdFundGoal">Crowd Fund</label>
            </div>
            {selectedGoalType === "crowd" && (
              <label htmlFor="crowdFundGoal" className="typo-grey-14">
                Allows multiple fans to contribute to your goal.
              </label>
            )}
          </div>
        </div>
      )}

      <p className="profile-error-message">{errors?.goalType?.message}</p>
    </div>
  );
};

export default GoalType;
