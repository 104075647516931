import Groupxpng from "../../img/group@2x.png";
import ProgressLoader from "../../svgfile/progressLoader.svg";
const SiginLoader = () => {
  return (
    <div className="siginParent-position">
      <div className="sign-in-group-parent">
        {/* siginParent-position */}
        <img className="sign-in-group-icon" alt="" src={Groupxpng} />
        <div className="sign-in-successful-parent">
          <div className="sign-in-successful">Sign in!</div>
          <div className="sign-in-please-wait-you-container">
            <p className="sign-in-please-wait">Please wait.....</p>
            <p className="sign-in-please-wait">
              You will be directed to homepage.
            </p>
          </div>
        </div>
        <div>
          <img className="sign-in-group-icon1" alt="" src={ProgressLoader} />
        </div>
      </div>
    </div>
  );
};

export default SiginLoader;
