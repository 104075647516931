import { useEffect, useState } from "react";
import { AdminMetaData } from "../config/axiosUtils";

const useCountry = () => {
  const [country, setCountry] = useState([
    { _id: 1, name: "Canada" },
    { _id: 2, name: "United States" },
    { _id: 3, name: "Germany" },
    { _id: 4, name: "France" },
    { _id: 5, name: "United Kingdom" },
    { _id: 6, name: "Australia" },
    { _id: 7, name: "Italy" },
    { _id: 8, name: "Spain" },
    { _id: 9, name: "Netherlands" },
  ]);

  useEffect(() => {
    async function fetchCategroyList() {
      try {
        const response = await AdminMetaData.fetchCountry();
        // console.log(response);
        setCountry(response.data?.country);
      } catch (error) {
        // console.error(error);
      }
    }

    fetchCategroyList();
  }, []);

  return country;
};

export default useCountry;
