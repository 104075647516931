import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BackBTn, LockIcon } from "../elements/SvgElements";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PasswordConfiormPassword = ({
  handleSubmit,
  forgotPassword,
  loading,
  register,
  errors,
  watch,
}) => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmpassword: false,
  });

  const togglePasswordVisibility = (key) => {
    setShowPassword({ ...showPassword, [key]: !showPassword[key] });
  };
  
  return (
    <>
      <span className="back-i-btn">
        <button className="backbtn" type="button">
          <Link to="..">{BackBTn}</Link>
        </button>
      </span>
      {/* forgot password here in content start from here  */}
      <div className="login-view mt-4 text-left">
        <h2 className="sub-head">
          Create new password<small className="fs"> 🔒</small>
        </h2>
        <p className="para mt-2 text-left">
          Create your new password. If you forget it, then you have to do forgot
          password.
        </p>
      </div>
      {/* ...forgot password content form */}
      <form className="user-login-form" onSubmit={handleSubmit(forgotPassword)}>
        <div className="form-start-login">
          <div className="form-brand">
            {/* form start from here */}
            <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
              <div className="form-group text-left mt-2">
                <label className="form-head mb-2" htmlFor="password">
                  New Password
                </label>
                <div
                  className={`password-input ${
                    errors?.password ? "error-border" : ""
                  }`}
                >
                  {LockIcon}
                  <input
                    type={showPassword.password ? "text" : "password"}
                    id="password"
                    autoComplete="off"
                    // className={`${
                    //   !showPassword.password && watch("newpassword")?.length > 0
                    //     ? "if-password"
                    //     : null
                    // }`}
                    placeholder="Enter your password "
                    {...register("password", {
                      minLength: {
                        value: 6,
                        message: "Password Should be More Than 6 Characters",
                      },
                      required: {
                        value: true,
                        message: "Password is Required",
                      },
                    })}
                  />

                  <button
                    type="button"
                    className="showHideIcon"
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {showPassword ? (
                      <FaEye color="#9E9E9E" size={20} />
                    ) : (
                      <FaEyeSlash color="#9E9E9E" size={20} />
                    )}
                  </button>
                </div>
                {errors?.password && (
                  <small className="error-message">
                    {errors?.password?.message}
                  </small>
                )}
              </div>

               {/* confirm password  */}
              <div className="form-group text-left mt-2">
                <label className="form-head mb-2" htmlFor="confirmpassword">
                Confirm New Password
                </label>
                <div
                  className={`password-input ${
                    errors?.confirmpassword ? "error-border" : ""
                  }`}
                >
                  {LockIcon}
                  <input
                    type={showPassword.confirmpassword ? "text" : "password"}
                    id="confirmpassword"
                    autoComplete="off"
                    // className={`${
                    //   !showPassword.password && watch("newpassword")?.length > 0
                    //     ? "if-password"
                    //     : null
                    // }`}
                    placeholder="Enter your password "
                    {...register("confirmpassword", {
                      minLength: {
                        value: 6,
                        message: "confirmpassword Should be More Than 6 Characters",
                      },
                      required: {
                        value: true,
                        message: "confirmpassword is Required",
                      },
                      validate: (value)=> value === watch("password") || "Confirm do not match"
                    })}
                  />

                  <button
                    type="button"
                    className="showHideIcon"
                    onClick={() => togglePasswordVisibility("confirmpassword")}
                  >
                    {showPassword ? (
                      <FaEye color="#9E9E9E" size={20} />
                    ) : (
                      <FaEyeSlash color="#9E9E9E" size={20} />
                    )}
                  </button>
                </div>
                {errors?.confirmpassword && (
                  <small className="error-message">
                    {errors?.confirmpassword?.message}
                  </small>
                )}
              </div>

              <div className="account-switch">
                <button
                  className={`long-btn-sign-in mt-2 mx-auto ${
                    loading ? "long-btn-sign-in-disabled" : null
                  }`}
                  disabled={loading}
                  type="submit"
                >
                  {!loading ? "continue" : "loading..."}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PasswordConfiormPassword;
