import { useEffect, useState } from "react";
import { currencyApi } from "../config/axiosUtils";

//hook used to converte the amount int usd when the goal amount is not in the usd currency.
const useConvertedAmounts = (goals, currency, toCurrency) => {
  const [convertedAmounts, setConvertedAmounts] = useState([]);

  useEffect(() => {
    const fetchConvertedAmount = async (goalPrice) => {
      try {
        // Call the currencyConverter API function with the goalPrice parameter
        const response = await currencyApi.currencyConverter(
          currency,
          parseFloat(goalPrice),
          toCurrency
        );

        return response?.data?.value;
      } catch (error) {
        console.error("Error fetching converted amount:", error);
        return null;
      }
    };

    // Fetch converted amount for each goal
    const fetchConvertedAmountsForGoals = async () => {
      const promises = goals.map(async (goal) => {
        const convertedAmount = await fetchConvertedAmount(goal.goalPrice);
        return convertedAmount;
      });

      // Wait for all promises to resolve
      const convertedAmounts = await Promise.all(promises);

      // Set converted amounts in state
      setConvertedAmounts(convertedAmounts);
    };

    fetchConvertedAmountsForGoals();
  }, [goals, currency]);

  return convertedAmounts;
};

export default useConvertedAmounts;
