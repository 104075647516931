import { AdminApi, api } from "./axiosConfig";

export const AuthApi = {
  signup: async (data) => {
    return await api.request({
      url: `/auth/signup`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  },
  signin: async (data) => {
    return await api.request({
      url: `/auth/login`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });
  },
  companionyourSelf: async (data, userId) => {
    return await api.request({
      url: `/auth/update-fighter/${userId}`,
      method: "patch",
      data: data,
    });
  },

  forgotPassword: async (data) => {
    const response = await api.request({
      url: "/auth/forgot-password",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  varifyOtp: async (data) => {
    const response = await api.request({
      url: "/auth/verify-otp",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response;
  },

  resetPassword: async (data) => {
    const response = await api.request({
      url: "/auth/reset-password",
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};

export const fighterApi = {
  fetchHomeData: async (token, category = "", sort = "") => {
    return await api.request({
      url: `/goal/all?sort=${sort}&category=${category}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  fetchGoalDetails: async (token, goalId) => {
    return await api.request({
      url: `/goal/${goalId}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  createGoals: async (token, goalData) => {
    console.log(goalData);
    return await api.request({
      url: "/goal/create",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: goalData,
    });
  },
  editGoal: async (token, goalId, data) => {
    return await api.request({
      url: `/goal/edit/${goalId}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },
  deleteGoal: async (token, goalId) => {
    return await api.request({
      url: `/goal/delete/${goalId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  ChangeOrder: async (token, data) => {
    return await api.request({
      url: `/goal/edit/order`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },
};

export const fetchCategory = async () => {
  return await api.request({
    url: "/category/all",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const userApi = {
  updateProfile: async (data, token) => {
    return await api.request({
      url: "/user/update",
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },
  updatePassword: async (data, token) => {
    return await api.request({
      url: "/user/change-password",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    });
  },
  youMightLike: async (token) => {
    return await api.request({
      url: `/user/fighters?random=${true}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  followUnfollow: async (token, fighterId, action) => {
    return await api.request({
      url: `/user/${fighterId}/${action}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getFollowedFighter: async (token) => {
    return await api.request({
      url: `/user/followed-fighters`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getFitherDetails: async (token, userName, sort, category) => {
    return await api.request({
      url: `/user/fighter/${userName}?sort=${sort}&category=${category}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  searchFighter: async (token, search) => {
    return await api.request({
      url: `/user/fighters?search=${search}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  updeSocialLInk: async (token, data) => {
    return await api.request({
      url: `/user/social-links`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },
  removeSocialLink: async (token, socialLinkId) => {
    return await api.request({
      url: "/user/social-links/remove",
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        socialLinkId: socialLinkId,
      },
    });
  },
  deleteAccount: async (token, data) => {
    return await api.request({
      url: "/user/delete-account",
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    });
  },
};

export const cartApi = {
  addToCart: async (token, data) => {
    return await api.request({
      url: `/cart/add`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: JSON.stringify(data),
    });
  },

  getCart: async (token) => {
    return await api.request({
      url: `/cart/view`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  updateCart: async (token, cartId, data) => {
    return await api.request({
      url: `/cart/update/${cartId}`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

      data: data,
    });
  },

  deleteCart: async (token, cartId) => {
    return await api.request({
      url: `/cart/delete/${cartId}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export const orderApi = {
  checkout: async (token, data) => {
    return await api.request({
      url: `/order/create`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },

  updateOrder: async (token, orderId, data) => {
    return await api.request({
      url: `/order/update/${orderId}`,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },

  fetchActivity: async (
    token,
    sending = "",
    receiving = "",
    shopType = "",
    goalType = ""
  ) => {
    return await api.request({
      url: `/order/getActivityGoals?sending=${sending}&receiving=${receiving}&shopType=${shopType}&goalType=${goalType}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  fetchRanking: async (token, time, limit) => {
    return await api.request({
      url: `/order/get-ranking?time=${time}&limit=${limit}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  fetchCompanionActivity: async (token) => {
    return await api.request({
      url: `/order/getActivityCompanion?goalType=`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export const messageApi = {
  FetchChatData: async (token, transactionId) => {
    return await api.request({
      url: `/message/getActivity-message?transactionId=${transactionId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  SendMessage: async (token, data) => {
    return await api.request({
      url: "/message/sendActivity-message",
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },
};

export const currencyApi = {
  currencyConverter: async (from = "CAD", amount = 100, toCurrency) => {
    return await api.request({
      url: "https://api.currencybeacon.com/v1/convert",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        api_key: process.env.REACT_APP_CURRENCYAPI,
        from: from,
        to: toCurrency || "USD",
        amount: amount,
      },
    });
  },
  FethedCurrencyList: async () => {
    return await api.request({
      url: `https://api.currencybeacon.com/v1/currencies?api_key=${process.env.REACT_APP_CURRENCYAPI}&type=fiat`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  GetExchangeRate: async (base = "USD", symbols = "") => {
    return await api.request({
      url: `https://api.currencybeacon.com/v1/latest?api_key=${process.env.REACT_APP_CURRENCYAPI}&base=${base}&symbols=${symbols}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export const stripeApi = {
  connectAccount: async (token) => {
    return await api.request({
      url: "/payment/connect-fighter-account",
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  cancelSubscription: async (token, subscriptionId) => {
    return await api.request({
      url: `/payment/cancel-subscription?subScheduleId=${subscriptionId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getConnectAccount: async (token) => {
    return await api.request({
      url: `/payment/get-connect-account`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  createLoginLink: async (token) => {
    return await api.request({
      url: `/payment/login-link`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getAccountBlance: async (token) => {
    return await api.request({
      url: `/payment/get-account-blance`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export const AdminMetaData = {
  fetchCountry: async () => {
    return await api.request({
      url: "/admin/country/all",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  currencyList: async () => {
    return await api.request({
      url: "/admin/currency/all",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  fighterPormotionCompeny: async () => {
    return await api.request({
      url: "/fighter-promotionompeny/all",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export const flagAPI = {
  reportFlag: async (data) => {
    return await AdminApi.request({
      url: "/createFlag",
      method: "POST",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      data: data,
    });
  },
};

export const twitterApi = {
  linkTwiiter: async (token) => {
    return await api.request({
      url: "/twitter/auth",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // "Access-Control-Allow-Origin": "*",
      },
    });
  },

  setupTwiiterStepTwo: async (token, data) => {
    return await api.request({
      url: "/twitter/setup-tweet-pesudo",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },

  postTweet: async (token, data) => {
    return await api.request({
      url: "/twitter/post-tweet",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    });
  },
};
