import { useEffect, useState } from "react";
import { currencyApi } from "../config/axiosUtils";

export default function useCurrencyConverterGoal(
  short_code,
  goalPrice,
  toCurrency
) {
  const [convertedAmount, setConvertedAmount] = useState();
  const [Conertedloading,setConertedloading] = useState(false)

  useEffect(() => {
    async function loadConvertedAmount() {
      if (!short_code || !goalPrice) {
        return null;
      }
      if (short_code === "USD" && !toCurrency) {
        setConvertedAmount(goalPrice);
        return;
      }

      try {
        setConertedloading(true)
        const response = await currencyApi.currencyConverter(
          short_code,
          parseFloat(goalPrice),
          toCurrency
        );
        setConvertedAmount(response?.data?.value);
      } catch (error) {
        
        console.error("Error fetching converted amount:", error);
        return null;
      }finally{
        setConertedloading(false)
      }
    }
    loadConvertedAmount();
  }, [short_code, goalPrice]);

  return {convertedAmount , Conertedloading};
}
