import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import { flagAPI } from "../config/axiosUtils";
import { useState } from "react";
import { toast } from "react-toastify";
const FlagModal = ({
  showFlagModal,
  setShowFlagModal,
  fighterId,
  senderId,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleReportFlag = async (data) => {
    try {
      setLoading(true);
      data.userGotFlagged = fighterId;
      data.userWhoFlagged = senderId;
      //   console.log(data);
      const res = await flagAPI.reportFlag(data);
      //   console.log(res);
      toast.success(res.data.message);
    } catch (error) {
      //   console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
      setShowFlagModal(false);
    }
  };
  return (
    <div>
      <Modal
        show={showFlagModal}
        onHide={() => setShowFlagModal(false)}
        id="flag-modal"
        centered
      >
        <form onSubmit={handleSubmit(handleReportFlag)}>
          <div className="position-relative">
            <h5 className="sec-head">Report This Fighter</h5>
            <button
              className="flag-close-btn border-0 bg-transparent"
              onClick={() => setShowFlagModal(false)}
              type="button"
            >
              <IoClose size={25} />
            </button>
          </div>

          <div className="flag-modaBody">
            <div>
              <label
                htmlFor="reason"
                className="typo-grey-16-600 text-black mb-2"
              >
                Reason
              </label>
              <textarea
                type="text"
                name="reason"
                id="reason"
                placeholder="Please enter the reason for reporting this fighter"
                className="form-control"
                rows={2}
                cols={2}
                {...register("reason", {
                  required: {
                    value: true,
                    message: "Please enter a reason",
                  },
                })}
              />

              {errors?.reason && (
                <small className="text-danger">{errors?.reason?.message}</small>
              )}
            </div>
            <button className="flag-btn" type="submit">
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default FlagModal;
