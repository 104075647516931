import { useState } from "react";
import { stripeApi } from "../config/axiosUtils";
import useAuth from "./useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function useCreateLoginLink() {
  const token = JSON.parse(useAuth()?.token);
  const [Loginloading, setLoginLoading] = useState(false);
  const navigate = useNavigate();
  const createLoginLink = async () => {
    setLoginLoading(true);

    try {
      const res = await stripeApi.createLoginLink(token);
      console.log(res);
      if (res?.data?.loginLink) {
        window.location.href = res?.data?.loginLink;
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 404) {
        return toast.error(error?.response?.data?.message);
      }
      if (error?.response?.status === 500) {
        return toast.error(error?.response?.data?.message);
      }

      toast.error("Error creating login link");
    } finally {
      setLoginLoading(false);
    }
  };

  return [Loginloading, createLoginLink];
}

export default useCreateLoginLink;
