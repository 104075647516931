import React from "react";
import { Link } from "react-router-dom";
import { BackBTn } from "../../elements/SvgElements";
import ProgressBar from "react-bootstrap/ProgressBar";
import { RiCheckboxCircleFill } from "react-icons/ri";
const CompanionSingUpSuccess = () => {
  return (
    <div className="form-brand-ct">
      <div className="d-flex justify-content-center align-items-center">
        <button className="backbtn" type="button">
          <Link to="">{BackBTn}</Link>
        </button>
        <div className="m-auto w-60">
          <ProgressBar now={100} variant="black" />
        </div>
      </div>
      <form className="user-login-form" id="signup-btn">
        <div className="form-start-login">
          {/* <div className="mt-5 companion-compelte">
          </div> */}
          <RiCheckboxCircleFill size={70} />
          <h2 className="mt-1">It's Time</h2>
          <p>Your account is ready. Future of Fighter Support starts here!</p>
        </div>
        <div className="account-switch">
          <Link
            type="submit"
            to={`/signin`}
            className={`long-btn-sign-in mt-2 mx-auto text-decoration-none`}
          >
            Go to My Account
          </Link>
        </div>
      </form>
    </div>
  );
};

export default CompanionSingUpSuccess;
