import { Outlet } from "react-router-dom";
import LoginFooterElement from "../elements/LoginFooterElement";
import { useLoading } from "../features/loadingHooks";

const SignupLayout = () => {
  const { globalLoading } = useLoading();
  return (
    <>
      <section className="user-welcome">
        <div className="d-flex">
          <div className="image-container">
          </div>
          <div
            className={`form-container cstpx-10 position-relative scroll-y-100 ${
              globalLoading ? "background-fade" : ""
            }`}
          >
            <Outlet />
          </div>
        </div>
      </section>
      <LoginFooterElement />
    </>
  );
};

export default SignupLayout;
