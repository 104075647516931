import { toast } from "react-toastify";
import { stripeApi } from "../config/axiosUtils";
import { useLoading } from "../features/loadingHooks";
import naviagateUserToLink from "../utils/naviagateUserToLink";
const useStripeConnectHook = () => {
  const { startGloablLoading, stopGlobalLoading } = useLoading();
  const token = localStorage.getItem("user-id");
  const handleStripeConnect = async () => {
    startGloablLoading();
    try {
      const res = await stripeApi.connectAccount(token);
      // console.log(res);
      if (res.status === 200) {
        naviagateUserToLink(res.data.accountLink);
      }
    } catch (error) {
      // console.log(error);
      if (error?.response?.status === 500 || error?.response?.status === 401) {
        return toast.error(error?.response?.data?.message);
      }

      return toast.error(error?.response?.data?.message);
    } finally {
      stopGlobalLoading();
    }
  };

  return handleStripeConnect;
};

export default useStripeConnectHook;
