import React from "react";

const SearchFighterShimmer = () => {
  return (
    <main className="main-content">
      <div className="search-profile-shimmer">
        <div className="spsh-title d-flex justify-content-center">
          <div className="shimmerBG"></div>
        </div>
        <div className="spsh-searchInput shimmerBG"></div>
        <div className="d-flex mt-4 spsh-list justify-content-between">
          <div className="spsh-list-details d-flex flex-column gap-2">
            <div className="spsh-list-detail shimmerBG"></div>
            <div className="spsh-list-detail shimmerBG"></div>
            <div className="spsh-list-detail shimmerBG"></div>
            <div className="spsh-list-detail shimmerBG"></div>
            <div className="spsh-list-detail shimmerBG"></div>
            <div className="spsh-list-detail shimmerBG"></div>
            <div className="spsh-list-detail shimmerBG"></div>
            <div className="spsh-list-detail shimmerBG"></div>
          </div>

          <div className="spsh-alpha-list shimmerBG"></div>
        </div>
      </div>
    </main>
  );
};

export default SearchFighterShimmer;
