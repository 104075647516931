import useAuth from "../services/useAuth";
import { FundedPercentage } from "../utils/Helper";
import LocaProgressBar from "./ProgressBar";
import Tooltip from "./TootlTip";

export const GoalCrowdDescription = ({
  goals,
  register,
  clearError,
  errors,
  currencySymbol,
  convertedAmount,
  convertAmountToUserCurrency,
  currentUserCurrency,
  convertedAmountLoad
}) => {
  const totalPercentage = FundedPercentage(
    goals.TotalCrowdFunded,
    goals?.goalPrice
  );
  const auth = useAuth();
  const user = JSON.parse(auth?.user);

  return (
    <div className="subscription-details mt-4">
      <div className="goal-progressbar">
        <LocaProgressBar totalPercent={totalPercentage} />
      </div>
      <div className="divii-5">
        <div className="divii-6">
          <div className={`divii-7 ${totalPercentage >= 100 ? "green" : ""}`}>
            {totalPercentage}%
          </div>
          <div className="divii-8">Funded</div>
        </div>

        <div className="divii-9">
          <div className="divii-10">Est.</div>
          <div className="divii-11">
            {currentUserCurrency?.short_code ||
              goals?.currencyDetails?.currency}{" "}
            {currentUserCurrency?.symbol || currencySymbol?.symbol}{" "}
             { !convertedAmountLoad ? convertAmountToUserCurrency
              ? parseFloat(convertAmountToUserCurrency).toFixed(2)
              : parseFloat(goals?.goalPrice).toFixed(2) : "loading..."} 
            <Tooltip
              content={
                !convertedAmountLoad ? `Estimated Conversion From USD $ 
                ${parseFloat(convertedAmount).toFixed(2)}` : `loading...`
                
              }
            >
              <i className="fa fs-6 pointer" role="button">
                &#xf059;
              </i>
            </Tooltip>
          </div>
        </div>
      </div>

      {goals?.creator !== user?._id && (
        <div className="crowd-contribution-amount">
          <input
            type="text"
            id="Amount"
            className={`${errors?.amount ? "error-border-profile" : null}`}
            placeholder={` ${goals?.currencyDetails?.currency}`}
            {...register("amount")}
            inputMode="numeric"
            autoComplete="off"
            autoFocus={true}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
          {<p className="profile-error-message">{errors?.amount?.message}</p>}
        </div>
      )}
    </div>
  );
};

// Goal description of subscription for other fighter goal
export const GoalSubDescription = ({
  goals,
  register,
  clearError,
  errors,
  currencySymbol,
  convertedAmount,
  convertAmountToUserCurrency,
  currentUserCurrency,
  convertedAmountLoad
}) => {
  return (
    <>
      <div className="goal-sub-desc-container">
        <div className="divii-9">
          <div className="divii-10">Est.</div>
          <div className="divii-11">
            <span className="currency">
              {currentUserCurrency?.short_code ||
                goals?.currencyDetails?.currency}
            </span>{" "}
            {currentUserCurrency?.symbol || currencySymbol?.symbol}{" "}
            { !convertedAmountLoad ? `${parseFloat(convertAmountToUserCurrency).toFixed(2)}` : `loading...` ||
              parseFloat(goals?.goalPrice).toFixed(2)}{" "}
            <Tooltip
              content={
                "Estimated Conversion From USD $" +
                parseFloat(convertedAmount).toFixed(2) || "XXX.."
              }
            >
              <i className="fa fs-6 pointer" role="button">
                &#xf059;
              </i>
            </Tooltip>
          </div>
        </div>
        <div className="goal-subdescription">
          <h5 className="mb-0 subscription-head">Subscription: </h5>{" "}
          {goals?.subscriptionType.length > 1 ? (
            <div className="subscribabel-drop-conatiner">
              <select
                className="form-control"
                id="subscribabel-select"
                {...register("subscriptionType")}
                onChange={() => {
                  clearError("subscriptionType");
                }}
              >
                <option value="">Select</option>
                {goals?.subscriptionType?.map((type, index) => (
                  <option value={type} key={goals?._id + index}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <span
              className="badge text-bg-light border border-dark rounded-pill py-2"
              role="button"
            >
              {goals?.subscriptionType[0]}
            </span>
          )}
        </div>
      </div>
    </>
  );
};
// Goal description of subscription for self goal
export const GoalSubDescriptionSelf = ({
  goals,
  currencySymbol,
  convertedAmount,
  convertedAmountLoad
}) => {
  return (
    <>
      <div className="goal-sub-desc-container">
        <div className="divii-9">
          <div className="divii-10">Est.</div>
          <div className="divii-11">
            <span className="currency">{goals?.currencyDetails?.currency}</span>{" "}
            {currencySymbol?.symbol}
            { !convertedAmountLoad ?  parseFloat(goals?.goalPrice).toFixed(2) : "loading.."}{" "}
            <Tooltip
              content={
                !convertedAmountLoad ? `Estimated Conversion From USD $ 
                ${parseFloat(convertedAmount).toFixed(2)}` : `loading...`
                
              }
            >
              <i className="fa fs-6 pointer" role="button">
                &#xf059;
              </i>
            </Tooltip>
          </div>
        </div>
        <div className="goal-subdescription">
          <h5 className="mb-0 subscription-head">Subscription: </h5>{" "}
          <span
            className="badge text-bg-light border border-dark rounded-pill py-2"
            role="button"
          >
            {goals?.subscriptionType.length > 1
              ? "Subscribable"
              : goals?.subscriptionType[0]}
          </span>
        </div>
      </div>
    </>
  );
};

// Goal description of single goal
export const GoalSingleDescription = ({
  goals,
  currencySymbol,
  convertedAmount,
  convertAmountToUserCurrency,
  currentUserCurrency,
  convertedAmountLoad
}) => {
  return (
    <>
      <div className="goal-sub-desc-container">
        <div className="divii-9">
          <div className="divii-10">Est.</div>
          <div className="divii-11">
            <span className="currency">
              {currentUserCurrency?.short_code ||
                goals?.currencyDetails?.currency}
            </span>{" "}
            {currentUserCurrency?.symbol || currencySymbol?.symbol}{" "}
            { !convertedAmountLoad ? convertAmountToUserCurrency
              ? parseFloat(convertAmountToUserCurrency).toFixed(2)
              : parseFloat(goals?.goalPrice).toFixed(2) : "loading..."} 
            <Tooltip
              content={
                !convertedAmountLoad ? `Estimated Conversion From USD $ 
                ${parseFloat(convertedAmount).toFixed(2)}` : `loading...`
                
              }
            >
              <i className="fa fs-6 pointer" role="button">
                &#xf059;
              </i>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

//function to convert from currency into currency symbol.

const getCurrencySymbol = (currencyName) => {
  try {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyName,
    });
    const currencySymbol = currencyFormatter
      .formatToParts(0)
      .find((part) => part.type === "currency").value;
    return currencySymbol;
  } catch (error) {
    console.error("Error retrieving currency symbol:", error);
    return null;
  }
};
