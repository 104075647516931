import React from "react";
import { useState } from "react";
import LeftAside from "./LeftAside";
import RightAside from "./RightAside";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";


const LayoutPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
     <TopBar isOpen={isOpen} setIsOpen={setIsOpen}/>
      <LeftAside isOpen={isOpen} setIsOpen={setIsOpen} />
      <Outlet />
      <RightAside />
      <Footer />
    </>
  );
};

export default LayoutPage;
