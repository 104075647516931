import React, { useEffect, useMemo } from "react";
import LocaProgressBar from "./ProgressBar";
import { Link } from "react-router-dom";
import { AiOutlineLink } from "react-icons/ai";
import { FundedPercentage, imgBasePath } from "../utils/Helper";
import useAuth from "../services/useAuth";
import Tooltip from "./TootlTip";
import useExchangeRate from "../services/useExchangeRate";
import useCurrencySymbol from "../services/useCurrencySymbol";
import useGetDollarExchange from "../services/useGetDollarExchange";
import copyToCLipboard from "../utils/copyToClipboard";

const OtherFighterGoals = ({
  goals,
  currency, // goal's currency
  userName,
  currencyDetails,
  currentUserCurrency,
}) => {
  const { user: authUser } = useAuth();
  const user = useMemo(() => JSON.parse(authUser), [authUser]);
  const userCurrency = useCurrencySymbol(user?.currency);
  const currencySymbol = useCurrencySymbol(currency);
  const dollarExchange = useGetDollarExchange(
    currencySymbol?.short_code,
    "USD"
  );

  const [getExchangeRate, exchangeRates, exchangeLoading] = useExchangeRate();

  useEffect(() => {
    if (currencySymbol?.short_code && userCurrency?.short_code) {
      getExchangeRate(currencySymbol?.short_code, userCurrency?.short_code);
    } else {
      getExchangeRate(
        currencySymbol?.short_code || "CAD",
        userCurrency?.short_code || "CAD"
      );
    }
  }, [currencySymbol?.short_code, userCurrency?.short_code]);

  return (
    <section className="goal-card-section">
      {goals?.map((goal) => (
        <div className="card goal-card" key={goal._id}>
          <div state={{ goalId: goal?._id }} style={{ textDecoration: "none" }}>
            <Link
              to={`${
                user?.role !== "companion" ? "/fighter" : "/companion"
              }/${userName}/${goal._id}`}
              className="goal-car-img"
            >
              <img
                src={`${imgBasePath}/${goal?.goalImage}`}
                className="card-img-top"
                alt="loading"
              />
            </Link>
            <div className="card-body">
              <Link
                to={`${
                  user?.role !== "companion" ? "/fighter" : "/companion"
                }/${userName}/${goal._id}`}
                className="text-decoration-none"
              >
                <h5 className="goal-title">{goal?.goalName}</h5>
                <div className="price-plan-subscription">
                  <h5 className="goal-price-typo">
                    {currentUserCurrency?.short_code || currency || "CAD"}{" "}
                    {currentUserCurrency?.symbol || currencyDetails?.symbol}{" "}
                    {!exchangeLoading
                      ? parseFloat(
                          exchangeRates?.[userCurrency?.short_code || "CAD"] *
                            goal?.goalPrice
                        ).toFixed(2)
                      : "loading..."}{" "}
                    <Tooltip
                      content={
                        !dollarExchange?.loading
                          ? `Estimated Conversion From USD $${(
                              dollarExchange?.rates?.USD * goal?.goalPrice
                            ).toFixed(2)}`
                          : "Estimated Conversion From USD $ XXX.."
                      }
                    >
                      <i className="fa fs-6 pointer" role="button">
                        &#xf059;
                      </i>
                    </Tooltip>
                  </h5>
                  {goal?.goalType === "subscription" &&
                    goal?.subscriptionType && (
                      <button className="freq-btn medium-typo">
                        {goal?.subscriptionType.length > 1
                          ? "Subscribable"
                          : goal?.subscriptionType[0]}
                      </button>
                    )}
                </div>
              </Link>
              <div>
                {goal?.goalType === "crowd" ? (
                  <div className="goal-progressbar">
                    <LocaProgressBar
                      totalPercent={FundedPercentage(
                        goal.TotalCrowdFunded,
                        goal.goalPrice
                      )}
                    />
                  </div>
                ) : (
                  <div className="prog"></div>
                )}

                <div className="goal-links">
                  <button
                    className="text-dark gift-copy-btn"
                    onClick={() =>
                      copyToCLipboard(`goal-detail/${goal?._id}`)
                    }
                  >
                    <AiOutlineLink size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default OtherFighterGoals;
