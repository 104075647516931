import React from "react";
import { Link } from "react-router-dom";
import Groupxpng from "../../img/group@2x.png";
const FighterSingUpSuccess = () => {
  return (
    <div className="siginParent-position">
    <div className="sign-in-group-parent ">
      <img className="sign-in-group-icon" alt="" src={Groupxpng} />
      <div className="sign-in-successful-parent">
        <div className="sign-in-successful">Verify Your Email</div>
        <div className="sign-in-please-wait-you-container">
          <p className="sign-in-please-wait">
            A verification link has been sent to xxxx@gmail.com. Please Verify your email to continue.
          </p>
        </div>
      </div>
      <div>
        {/* <img className="sign-in-group-icon1" alt="" src={ProgressLoader} /> */}
        <Link to="/signin" className="signup-sucess-p-btn  text-decoration-none">
        Click here to login
        </Link>
      </div>
    </div>
  </div>
  );
};

export default FighterSingUpSuccess;
