import GoalCardShimmer from "./GoalCardShimmer";

const ProfileShimmer = () => {
  return (
    <main className="main-content mt-2">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12">
          <div className="tile">
            <div className="tile-content">
              <div className="bannerShimmer shimmerBG ">
                <div className="user-profileshim shimmerBG"></div>
              </div>

              <div className="profile-details-shimmer">
                <div className="user-content">
                  <div className="user-details">
                    <div className="userProfile-shim shimmerBG">
                      {/* for mobile */}
                      <div className="d-lg-none d-mb-block">
                        <div className="d-flex gap-1 align-items-center justify-content-end"></div>
                      </div>
                      {/* for mobile */}
                    </div>
                    <div className="userProfile-shim shimmerBG  mt-2"></div>
                    <div className="shimmerBG profile-desc-shim mt-2"></div>
                  </div>
                </div>
                <div className="log-user-info-right d-mb-none">
                  <div className="fighter-top text-end">
                    <button className="fav-shimmerbtn shimmerBG"></button>
                  </div>
                  <div className="fighter-bottom d-flex gap-2 flex-column">
                    <div className="d-flex align-items-center gap-1 shimmerBG data-join-shm"></div>
                    <div className="d-flex gap-1 align-items-center justify-content-end">
                      <span className="shimmerBG socail-link-shimmer"></span>
                      <span className="shimmerBG socail-link-shimmer"></span>
                      <span className="shimmerBG socail-link-shimmer"></span>
                    </div>
                  </div>
                </div>
              </div>
              {/* for mobile detail favourite  */}
              <div className="d-lg-none d-mb-flex f-mb-detail">
                <div className="align-items-center d-mb-flex shimmerBG data-join-shm"></div>
                <div className="align-items-center d-mb-flex shimmerBG data-join-shm"></div>
                {/* follow buton */}
              </div>

              {/* for mobile ened here */}
            </div>

            <div className="filter-goal d-none d-mb-block">
              <button className="fav-shimmerbtn shimmerBG"></button>
            </div>
          </div>
        </div>
      </div>

      {/* Goal shimmer  start */}

      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12 d-flex flex-wrap flex-grow-shimmer">
          <GoalCardShimmer />
          <GoalCardShimmer />
          <GoalCardShimmer />
          <GoalCardShimmer />
          <GoalCardShimmer />
          <GoalCardShimmer />
        </div>
      </div>
      {/* Goal shimmer  end */}
    </main>
  );
};

export default ProfileShimmer;
