import { lazy, useEffect, useState, Suspense } from "react";
import "./App.css";
import "./Responsive.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import LandingLog from "./Pages/LandingLog";
import "bootstrap/dist/css/bootstrap.min.css";
import FighterHome from "./Pages/FighterHome";
import LayoutPage from "./components/LayoutPage";
import CompanionHome from "./Pages/CompanionHome";
import SearchFighter from "./Pages/SearchFighter";
import ActivitiesFighter from "./Pages/ActivitiesFighter";
import CartFighter from "./Pages/CartFighter";
import AccountFighter from "./Pages/AccountFighter";
import PaymentInfo from "./Pages/PaymentInfo";
import LikeProfile from "./Pages/LikeProfile";
import About from "./Pages/About";
import HelpCenter from "./Pages/HelpCenter";
import TermsService from "./Pages/TermsService";
import Faq from "./Pages/Faq";
import AddGoal from "./Pages/AddGoal";
import FavoriteFighter from "./Pages/FavoriteFighter";
import OtherFighterProfile from "./Pages/OtherFighterProfile";
import GoalsDetails from "./Pages/GoalsDetails";
import SignupLayout from "./Layout/SignupLayout";
import FighterSignup from "./Pages/FighterSignup";
import CompanionSignup from "./Pages/CompanionSignup";
import SignIn from "./Pages/SignIn";
import { LoadingProvider } from "./features/loadingHooks";
import Unauthorize from "./Pages/Unauthorize";
import RoleAuth from "./Layout/RoleAuth";
import useAuth from "./services/useAuth";
import FighterGaurd, { CompanionGaurd } from "./Layout/Gaurd";
import NotFound from "./Pages/NotFound";
import { currencyApi, fetchCategory } from "./config/axiosUtils";
import { CategoryProvider } from "./features/categoryHooks";
import { defaultCategory } from "./utils/Helper";
import PasswordChange from "./Pages/PasswordChange";
import GoalActivity from "./components/GoalActivity";
import SubscriptionActivity from "./components/SubscriptionActivity";
import SurpriseActivity from "./components/SurpriseActivity";

import RankingPage from "./Pages/Ranking/RankingPage";
import FighterGoalDetails from "./components/FighterGoalDetails";
import EditGoal from "./Pages/EditGoal";
import AccountCompanion from "./Pages/AccountCompanion";
import ContributionSuccessfull from "./components/CustomLoader/ContributionSuccessfull";
import CompanionYourSelf from "./Pages/CompanionYourSelf";
import FighterYourSelf from "./Pages/FighterYourSelf";
import { useDispatch } from "react-redux";
import { setCurrency } from "./features/currencySlice";
import useTokenExpirationHooks from "./services/useTokenExpirationHooks";
import ActivitiesCompanion from "./Pages/ActivitiesCompanion";
import ForgotPassword from "./Pages/ForgotPassword";
import FooterData from "./Pages/FooterData";
const PaymentSuccess = lazy(() => import("./Pages/PaymentSuccess"));
const Works = lazy(() => import("./Pages/Works"));

function App() {
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  // token expiration custom hooks
  useTokenExpirationHooks();

  const auth = useAuth();

  //routes
  const router = createBrowserRouter([
    {
      path: "/landing",
      element:
        auth?.isLoggedIn && JSON.parse(auth?.token) ? (
          <Navigate to={"/fighter"} replace={true} />
        ) : (
          <LandingLog />
        ),
    },
    {
      path: "/test",
      element: <ContributionSuccessfull />,
    },
    {
      path: "/signup",
      element:
        auth?.isLoggedIn && JSON.parse(auth?.token) ? (
          <Navigate to={"/fighter"} replace={true} />
        ) : (
          <SignupLayout />
        ),
      children: [
        {
          index: true,
          element: <Navigate to="fighter" replace={true} />, // this is work as default route
        },

        {
          path: "fighter",
          element: <FighterSignup />,
        },
        {
          path: "fighter/your-self",
          element: <FighterYourSelf />,
        },
        {
          path: "companion",
          element: <CompanionSignup />,
        },
        {
          path: "companion/your-self",
          element: <CompanionYourSelf />,
        },
      ],
    },
    {
      path: "/unauthorized",
      element: <Unauthorize />,
    },
    {
      element: <SignupLayout />,
      children: [
        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPassword />,
        },
      ],
    },
    {
      path: "/",
      element: <RoleAuth allowedRoles={[JSON?.parse(auth?.user)?.role]} />,
      children: [
        {
          index: true,
          element: <Navigate to="fighter" replace={true} />, // this is work as default route
        },
        {
          path: "fighter",
          element: (
            <FighterGaurd
              components={<LayoutPage />}
              role={JSON?.parse(auth?.user)?.role}
            />
          ),
          children: [
            {
              index: true,
              element: <FighterHome />,
              errorElement: <>{}</>,
            },
            {
              path: ":userName/:goalId",
              element: <GoalsDetails token={JSON.parse(auth?.token)} />,
            },
            {
              path: "goal-detail/:goalId",
              element: <FighterGoalDetails token={JSON.parse(auth?.token)} />,
            },
            {
              path: "edit-goal/:goalId",
              element: <EditGoal token={JSON.parse(auth?.token)} />,
            },
            {
              path: "account",
              element: <AccountFighter />,
            },
            {
              path: "change-password",
              element: <PasswordChange />,
            },
            {
              path: "mightlike",
              element: <LikeProfile />,
            },
            {
              path: ":userName",
              element: <OtherFighterProfile />,
            },
            {
              path: "favourite",
              element: <FavoriteFighter />,
            },
            {
              path: "cart",
              element: <CartFighter />,
            },
            {
              path: "search",
              element: <SearchFighter />,
            },
            {
              path: "create-goal",
              element: <AddGoal />,
            },
            //footer page route
            {
              path: "f/:id",
              element: <FooterData />,
            },
            {
              path: 'help-center',
              element: <HelpCenter />,
            }, 
            {
              path: "faq",
              element: <Faq />,
            },
            {
              path: "activites",
              element: <ActivitiesFighter />,
              children: [
                {
                  path: "",
                  element: <GoalActivity token={JSON.parse(auth?.token)} />,
                },
                {
                  path: "subscriptions",
                  element: (
                    <SubscriptionActivity token={JSON.parse(auth?.token)} />
                  ),
                },
                {
                  path: "surprise",
                  element: <SurpriseActivity token={JSON.parse(auth?.token)} />,
                },
              ],
            },
            {
              //route for ranking
              path: "ranking",
              element: <RankingPage />,
            },
            {
              path: "payment",
              element: <PaymentInfo />,
            },
          ],
        },
        {
          path: "companion",
          element: (
            <CompanionGaurd
              components={<LayoutPage />}
              role={JSON?.parse(auth?.user)?.role}
            />
          ),
          children: [
            {
              index: true,
              element: <CompanionHome />,
            },
            {
              path: "account",
              element: <AccountCompanion />,
            },
            {
              path: ":userName",
              element: <OtherFighterProfile />,
            },
            {
              path: "change-password",
              element: <PasswordChange />,
            },
            {
              path: "cart",
              element: <CartFighter />,
            },
            {
              path: "search",
              element: <SearchFighter />,
            },
            {
              path: "mightlike",
              element: <LikeProfile />,
            },
            {
              path: ":userName/:goalId",
              element: <GoalsDetails token={JSON.parse(auth?.token)} />,
            },
            {
              path: "ranking",
              element: <RankingPage />,
            },
            {
              path: 'f/:id',
              element: <FooterData />,
            }, 
            {
              path: 'help-center',
              element: <HelpCenter />,
            }, 
            {
              //route for activities
              path: "activites",
              element: <ActivitiesCompanion />,
            },
          ],
        },
      ],
    },

    {
      path: "payment/success",
      element: (
        <Suspense loading="loading">
          <PaymentSuccess />
        </Suspense>
      ),
    },
    {
      path: "preview/:userName",
      element: <OtherFighterProfile />,
    },

    {
      path: "preview/goal-detail/:goalId",
      element: <GoalsDetails token={JSON.parse(auth?.token)} />,
    },
    {
      path: "f/:id",
      element: <FooterData />,
    },
    {
      path: 'help-center',
      element: <HelpCenter />,
    }, 
    {
      path: "faq",
      element: <Faq />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  useEffect(() => {
    fetchCategory()
      .then((res) => {
        if (res?.status === 200 && res?.data?.category?.length === 0) {
          setCategories(defaultCategory);
        }
        setCategories(res?.data?.category);
        // setGloablCategory();
      })
      .catch((e) => {
        setCategories(defaultCategory);
      });

    currencyApi
      .FethedCurrencyList()
      .then((res) => {
        dispatch(
          setCurrency({
            currency: res?.data?.response,
          })
        );
      })
      .catch((e) => {
        console.log("error data", e);
      });
  }, []);

  return (
    <LoadingProvider>
      <CategoryProvider categories={categories}>
        <RouterProvider router={router} />
      </CategoryProvider>
    </LoadingProvider>
  );
}

export default App;
