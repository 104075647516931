import React, { useEffect } from "react";
import BrandImg from "../img/NewLogo.png";

const TopBar = ({ isOpen, setIsOpen }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".topbar")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  const handleToggleClick = () => {
    setIsOpen(!isOpen); // Toggles the isOpen state
  };

  return (
    <>
      <div className="topbar d-lg-none d-mb-block">
        <div className="tile">
          <div className="d-flex top-fl">
            <div className="top-toggle">
              <button className="mobile-toggle" onClick={handleToggleClick}>
                <span className={`breadcrumb-icon ${isOpen ? "open" : ""}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M4 7h3m13 0h-9m9 10h-3M4 17h9m-9-5h16"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div className="top-icon">
              <img className="l-form-brand w-40" src={BrandImg} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
