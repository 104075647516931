const { useEffect } = require("react");

// custom hook to check if the token is expired
const useTokenExpirationHooks = () => {
  // Function to check if the token is expired
  const isTokenExpired = (token) => {
    if (!token) return false;
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    return Date.now() >= decodedToken.exp * 1000;
  };

  // Function to handle token expiration
  const handleTokenExpiration = () => {
    const token = localStorage.getItem("user-id");
    if (isTokenExpired(token)) {
      // Token expired, log out the user
      localStorage.removeItem("user-id");
      localStorage.removeItem("persist:wishtender");
      window.location.reload(); // Refresh the page to trigger the sign-in flow
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleTokenExpiration();
    }, 30000);

    return () => clearInterval(interval);
  }, []);
};

export default useTokenExpirationHooks;
