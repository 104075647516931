const usePostTweet = () => {
  const postTweet = (url) => {
    try {
      const tweetTextWithUrl = `${"I just added a new item to my wishlist.\n\nBuy it for me here:"}\n${url}`;
      const encodedTweet = encodeURIComponent(tweetTextWithUrl);
      const tweetUrl = `https://x.com/intent/tweet?text=${encodedTweet}`;

      // Open the URL in a new tab
      window.open(tweetUrl, "_blank");
    } catch (error) {
      console.error("Error opening tweet URL:", error);
    }
  };

  return [postTweet];
};

export default usePostTweet;
