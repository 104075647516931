import ProgressBar from "react-bootstrap/ProgressBar";

function LocaProgressBar({ totalPercent = 60 }) {
  // const now = 60;
  return (
    <ProgressBar
      variant={totalPercent >= 100 ? "success" : "black"}
      now={totalPercent}
      label={`${totalPercent}%`}
      visuallyHidden
    />
  );
}

export default LocaProgressBar;
