import { useState } from "react";
import { toast } from "react-toastify";
import { stripeApi } from "../config/axiosUtils";

const useCancelSubscription = () => {
  const [loading, setLoading] = useState(false);
  const cancelSubscription = async (token, subscriptionId) => {
    try {
      setLoading(true);
      const response = await stripeApi.cancelSubscription(
        token,
        subscriptionId
      );
      console.log(response);

      if (response.status === 200) {
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 400) {
        return toast.error(error.response?.data?.message);
      }

      if (error?.response?.status === 500) {
        return toast.error(error.response?.data?.message);
      }

      return toast.error("Failed to cancel subscription!");
    } finally {
      setLoading(false);
    }
  };
  return { cancelSubscription, loading };
};

export default useCancelSubscription;
