import React, { useEffect, useState } from "react";
import TopSection from "../components/TopSection";
import SubscriptionActivity from "../components/SubscriptionActivity";
import useAuth from "../services/useAuth";
import ContributionActivity from "./ContributionActivity";
import useExchangeRate from "../services/useExchangeRate";
import { useSelector } from "react-redux";

const ActivitiesCompanion = () => {
  const [activeTab, setActiveTab] = useState("contributions");
  const auth = useAuth();
  const [currencyDetails, setCurrencyDetails] = useState(null);
  const currency = useSelector((state) => state?.currency?.currency);
  const [getExchangeRate, exchangeRates] = useExchangeRate();
  const user = JSON.parse(auth?.user);
  const token = JSON.parse(useAuth()?.token);

  function handleTabChange(tab) {
    setActiveTab(tab);
  }

  useEffect(() => {
    if (user) {
      const data = currency?.find((item) => item.short_code === user.currency);
      setCurrencyDetails(data);
    }
  }, [user?.currency]);

  useEffect(() => {
    getExchangeRate("USD");
  }, [currencyDetails, user?.currency]);

  return (
    <main className="main-content">
      <TopSection title={"Activities"} />
      {/* section for activities content */}
      <section className="acttivities-content">
        <div className="row justify-content-center">
          <div className="col-md-11 col-sm-12 col-xs-12 col-lg-11 position-relative">
            <div className="activity-nav">
              <button
                className={`text-decoration-none goal-activity-tab ${
                  activeTab === "contributions" ? "active" : ""
                }`}
                onClick={() => handleTabChange("contributions")}
              >
                Contributions
              </button>
              <button
                className={`text-decoration-none sub-activity-tab ${
                  activeTab === "subscription" ? "active" : ""
                }`}
                onClick={() => handleTabChange("subscription")}
              >
                Subscriptions
              </button>
            </div>
            {activeTab === "contributions" && (
              <ContributionActivity
                token={token}
                exchangeRates={exchangeRates}
                currencyDetails={currencyDetails}
              />
            )}
            {activeTab === "subscription" && (
              <SubscriptionActivity
                token={token}
                exchangeRates={exchangeRates}
                currencyDetails={currencyDetails}
              />
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default ActivitiesCompanion;
