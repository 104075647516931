import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TwiterLinkImg from "../img/lux_tweet.png";
import useInititateTwitterLink from "../services/useInitiateTwiiterLink";
import { useSearchParams } from "react-router-dom";
import useAuth from "../services/useAuth";
import { useForm } from "react-hook-form";

const stepTitles = ["Link Twitter", "Tweet Settings"];

const XsetupModal = ({ showTwiterModaldModal, setshowTwiterModaldModal }) => {
  const { register, handleSubmit } = useForm();
  const token = JSON.parse(useAuth()?.token);
  const [twitterLink, initiateTwitterLink] = useInititateTwitterLink();
  let [searchParams] = useSearchParams();

  const [currentStep, setCurrentStep] = useState(1);
  const numSteps = stepTitles.length;

  // Check URL parameters and update the state
  useEffect(() => {
    const setDialog = searchParams.get("setDialog");
    const setTwitterSetupStep = searchParams.get("setTwitterSetupStep");

    if (setDialog === "autoTweetsStepper" && setTwitterSetupStep === "1") {
      setshowTwiterModaldModal(true);
      setCurrentStep(2); // Set to the second step
    } else {
      setshowTwiterModaldModal(false);
    }
  }, [searchParams, setshowTwiterModaldModal]);


  const handleCloseTwitterModal = () => {
    setshowTwiterModaldModal(false);
  };

  function initiateTweeiLink() {
    window.location.href = `${process.env.REACT_APP_LOCAL_API_URL}/twitter/auth?token=${token}`;
  }

  const steps = Array.from({ length: numSteps }, (_, index) => index + 1);

  return (
    <>
      <div>
        <Modal
          show={showTwiterModaldModal}
          onHide={handleCloseTwitterModal}
          id="modal-container"
          centered
        >
          <div>
            <h5 className="sec-head">Twitter Integration</h5>
            {/* Steps sections */}
            <div className="step-container">
              <div className="stepper-horizontal" id="stepper1">
                {steps.map((stepNum) => (
                  <div
                    key={stepNum}
                    className={`step ${
                      currentStep === stepNum ? "editing" : ""
                    } ${currentStep > stepNum ? "done" : ""}`}
                  >
                    <div className="step-circle">
                      <span className="span">{stepNum}</span>
                    </div>
                    <div className="step-title">{stepTitles[stepNum - 1]}</div>
                    <div className="step-bar-left"></div>
                    <div className="step-bar-right"></div>
                  </div>
                ))}
              </div>
            </div>

            {/* Step one */}
            {currentStep === 1 && (
              <div className="step-body">
                {/* Step heading */}
                <div className="step-heading typeo-32-38">
                  Step 1: Link Twitter
                </div>
                {/* Step content */}
                <p className="step-one-info typeo-18-24-u mb-0">
                  Set Up Twitter To Auto Tweet When You Receive A Gift.
                </p>
                <div className="step-one-sub-body">
                  <img src={TwiterLinkImg} alt="Twitter Link" />
                </div>
                <div className="social-btn-group">
                  <button
                    className="btn50 save-social-btn goal-title text-white all-btn"
                    onClick={initiateTweeiLink}

                    // disabled={twitterLink.loading}
                  >
                    {twitterLink.loading ? "Loading..." : "Link Twitter"}
                  </button>
                </div>
              </div>
            )}

            {/* Step two */}
            {currentStep === 2 && (
              <form
                className="step-body"
                onSubmit={handleSubmit(initiateTwitterLink)}
              >
                {/* Step heading */}
                <div className="step-heading typeo-32-38">
                  Step 2: Tweet Settings
                </div>
                {/* Step content */}
                <div className="step-one-info mb-0 mx-auto">
                  <label>Configure your tweet settings</label>
                  <div className="text-start">
                    <input
                      type="checkbox"
                      name="pseudonym"
                      id="pseudonym"
                      className="me-2"
                      {...register("pseudonym")}
                    />
                    <label htmlFor="pseudonym">Always hide pseudonym</label>
                  </div>
                  <div className="text-start">
                    <input
                      type="checkbox"
                      name="includeGiftimage"
                      id="includeGiftimage"
                      className="me-2"
                      {...register("includeGiftimage")}
                    />
                    <label htmlFor="includeGiftimage">Include Gift Image</label>
                  </div>
                  <div className="text-start">
                    <input
                      type="checkbox"
                      name="includeWishlistLink"
                      id="includeWishlistLink"
                      className="me-2"
                      {...register("includeWishlistLink")}
                    />
                    <label htmlFor="includeWishlistLink">Include Wishlist Link</label>
                  </div>
                </div>
                <div className="twitterSetup-surprise">
                  <label
                    htmlFor="minAmount"
                    className="typo-grey-16-600 text-black mb-1"
                  >
                    Minimum gift amount
                  </label>
                  <input
                    type="text"
                    name="minimum_gift_amount"
                    className="form-control"
                    id="minAmount"
                    placeholder="$ 0.00"
                    {...register("minimum_gift_amount")}
                  />
                </div>
                <div className="step-one-sub-body">
                  <img src={TwiterLinkImg} alt="Twitter Settings" />
                </div>
                <div className="social-btn-group">
                  <button
                    className="btn50 save-social-btn goal-title text-white all-btn"
                    disabled={twitterLink.loading}

                    type="submit"
                  >

                    {twitterLink.loading ? "Loading..." : "Save Twitter Account"}
                    
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default XsetupModal;
