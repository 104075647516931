import React, { useState, useEffect, useRef } from "react";
import "../CustomSlect.css"; // Create a CSS file and import it here

const CustomSelect = ({
  EditCategory,
  options,
  setValue,
  errors,
  clearErrors,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    categoryName: EditCategory || "Category",
  });
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!selectRef.current.contains(e.target)) {
        closeAllSelect();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    clearErrors("goalCategory");
    setValue("goalCategory", option?.categoryName || "Other");
    closeAllSelect();
  };

  const closeAllSelect = () => {
    setIsOpen(false);
  };

  return (
    <div
      className={`custom-select ${isOpen ? "select-arrow-active" : ""}`}
      ref={selectRef}
    >
      <svg
        className="drop-down-arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8597 10.118C19.2755 9.70221 19.9496 9.70221 20.3654 10.118C20.7812 10.5338 20.7812 11.2079 20.3654 11.6237L13.9774 18.0117C13.5616 18.4275 12.8875 18.4275 12.4717 18.0117L6.08365 11.6237C5.66787 11.2079 5.66787 10.5338 6.08365 10.118C6.49943 9.70221 7.17354 9.70221 7.58933 10.118L13.2245 15.7532L18.8597 10.118Z"
          fill="#616161"
        />
      </svg>
      <div
        className={`select-selected ${
          errors?.goalCategory ? "error-border-profile" : ""
        }`}
        onClick={handleSelectClick}
      >
        {selectedOption?.categoryName}
      </div>

      <div className={`select-items ${isOpen ? "" : "select-hide"}`}>
        {options.map((option) => (
          <div
            key={option._id}
            className={`select-item ${
              selectedOption?.categoryName === option?.categoryName
                ? "same-as-selected"
                : ""
            }`}
            onClick={() => handleOptionClick(option)}
          >
            {option?.categoryName}
          </div>
        ))}
      </div>
      <p className="profile-error-message">{errors?.goalCategory?.message}</p>
    </div>
  );
};

export default CustomSelect;
