const naviagateUserToLink = (Link) => {
  const anchor = document.createElement("a");
  anchor.href = Link;
  anchor.target = "_blank";

  const clickEvent = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  anchor.dispatchEvent(clickEvent);
};

export default naviagateUserToLink;
