import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { MdLock } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { userApi } from "../config/axiosUtils";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAuth from "../services/useAuth";
import { handleLogout } from "../utils/Helper";

const AccountDeleteModal = ({ showPasswordModal, setshowPasswordModal }) => {
  const token = JSON.parse(useAuth()?.token);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [confrimmessage, setconfrimmessage] = useState(false);

  const handleClosePasswordModal = () => {
    reset();
    setshowPasswordModal(false);
    setconfrimmessage(false);
  };

  async function handleDeleteAccount(data) {
    try {
      const res = await userApi.deleteAccount(token, data);
      console.log(res);
      setconfrimmessage(true);
    } catch (e) {
      console.log(e);
      toast.error(e?.response?.data?.message || e?.message);
      if (e?.response?.status === 401) {
        toast.error("Something went wrong try later");
      }
      if (e?.response?.status === 500) {
        toast.error(e?.response?.data?.message);
      }
      if (e?.response?.status === 400) {
        toast.error(e?.response?.data?.message);
      }
    } finally {
    }
  }

  return (
    <>
      <div>
        <Modal
          show={showPasswordModal}
          onHide={handleClosePasswordModal}
          id="modal-container"
          centered
        >
          {!confrimmessage && (
            <form onSubmit={handleSubmit(handleDeleteAccount)}>
              <h5 className="sec-head">Delete Account</h5>
              <div className="deletPassrdmodal-body">
                {/* password input sectin */}
                <div className="password-grup">
                  <label
                    htmlFor="password "
                    className="typo-grey-16-600 text-black"
                  >
                    Password
                  </label>
                  <div>
                    <MdLock color="#9E9E9E" />

                    <input
                      type={showPassword ? "text" : "password"}
                      autoComplete="off"
                      {...register("password", {
                        required: "Password is required",
                      })}
                      placeholder="Enter Your Password"
                    />
                    <FaEye
                      color="#9E9E9E"
                      className="password-eye"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                  {errors?.password && (
                    <p className="text-danger mb-0">
                      {errors?.password?.message}
                    </p>
                  )}
                </div>
                {/* password message input sectin */}
                <div className="passwordmessage-grup">
                  <label
                    htmlFor="passwordmessage"
                    className="typo-grey-16-600 text-black"
                  >
                    Write a Reason
                  </label>
                  <textarea
                    rows="4"
                    cols="60"
                    type="text"
                    autoComplete="off"
                    name="passwordmessage"
                    {...register("reason", {
                      required: "Reason is required",
                    })}
                    id="passwordmessage"
                  />
                  {errors?.reason && (
                    <p className="text-danger mb-0">
                      {errors?.reason?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="social-btn-group">
                <button
                  className="save-social-btn goal-title text-white all-btn"
                  type="submit"
                  style={{
                    width: "51%",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}

          {/* account delection succes modal data  */}

          {confrimmessage && (
            <div>
              <div>
                <h5 className="sec-head"> Account Deletion Request Received</h5>
                <p className="deletPassrdmodal-success typo-grey-14">
                  A Confirmation Email has been Sent to Verify Your Account
                  Deletion Request
                </p>
                <div className="social-btn-group">
                  <button
                    className="save-social-btn goal-title text-white all-btn"
                    onClick={() => {
                      handleClosePasswordModal();
                      handleLogout();
                    }}
                    style={{
                      width: "51%",
                    }}
                  >
                    Go Check Your Email
                  </button>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default AccountDeleteModal;
