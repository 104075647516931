import axios from "axios";
import { useState } from "react";

const useValidEmail = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const validEmail = async (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setData(null);
      return;
    }
    if (!email) return;

    setLoading(true);
    try {
      const res = await axios.get(
        `https://emailvalidation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_VALIDATE_MAIL}&email=${email}`
      );
      //   console.log(res.data);
      setData(res.data.deliverability);
    } catch (error) {
      setData("PASS");
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return [data, loading, error, validEmail];
};

export default useValidEmail;
